import QueryString from "querystring";
import type { GetServerSidePropsContext, GetServerSideProps } from "next";
import { PageRenderer } from "@/renderers";
import type { ServiceArgs } from "@/services";
import { TemplatePageService } from "@/services";
import type { PageResponse } from "@/types";
import { AuthenticatedUser, Authentication } from "@/libs";

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const { resolvedUrl, req, res, query } = context;
  const accessToken = await Authentication.getUserAccessToken(req, res);
  const userActions = await Authentication.getUserActions(accessToken);
  const user: AuthenticatedUser | undefined = await Authentication.getUser(
    req,
    res
  );
  const serviceArgs: ServiceArgs = {
    path: resolvedUrl,
    accessToken,
    user,
    queryParams: QueryString.stringify(query, "&", "=", {
      encodeURIComponent: (str) => str,
    }),
    userActions,
  };
  const page = await TemplatePageService.retrievePageData(serviceArgs);
  return {
    props: { page },
    notFound: page === null,
  };
};

const TemplatePage = ({ page }: PageResponse) => {
  if (page) return PageRenderer.render(page);
  return null;
};

export default TemplatePage;
